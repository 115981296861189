import { Heading } from 'components/Heading';
import Img from 'gatsby-image';
import React from 'react';
import { Box } from 'src/components/Box';
import { Carousel } from 'src/components/Carousel';
import { Paragraph } from 'src/components/Paragraph';
import { useTheme } from 'src/components/ThemeProvider';
import { useWindowResize } from 'src/helpers/useWindowResize';
import 'swiper/swiper-bundle.css';

const AppExperiencesCard = ({ title, description, img }) => (
    <Box display="block" maxWidth="275px">
        <Box
            display="block"
            height="auto"
            shadow="raised"
            // custom radius for phone images
            rounded="15px"
        >
            <Img
                style={{
                    height: 'auto',
                    maxWidth: '100%',
                    // objectFit: 'cover',
                }}
                fluid={img}
            />
        </Box>
        <Box pt="spacing-xl">
            <Heading kind="h5" mb="spacing">
                {title}
            </Heading>

            <Paragraph fontSize="body" color="bodyText">
                {description}
            </Paragraph>
        </Box>
    </Box>
);

export const AppExperiencesPhones = ({ theSlides, ...props }: any) => {
    const { windowWidth } = useWindowResize();
    const { breakpoint } = useTheme();

    let spaceBetween = 20;
    let slideWidth = 175;

    if (windowWidth > breakpoint.stripped.md) {
        slideWidth = 225;
    }

    if (windowWidth > breakpoint.stripped.lg) {
        spaceBetween = 60;
        slideWidth = 250;
    }
    return (
        <Carousel
            slidesPerView="auto"
            slideWidth={slideWidth}
            spaceBetween={spaceBetween}
            style={{
                overflow: 'visible',
                width: slideWidth,
                marginLeft: '0',
            }}
            slides={theSlides.map((item, key) => (
                <AppExperiencesCard
                    key={key}
                    title={item.title}
                    description={item.subtext}
                    img={item.image}
                />
            ))}
        />
    );
};
