import styled from '@emotion/styled';
import { Box, BoxProps } from 'components/Box';
import { Button } from 'components/Button';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import React from 'react';
import { Section } from '../Section';

export function MethodsCTA({
    title,
    href = '/contact',
    buttonText = 'Contact us',
    flairColor,
    ...props
}: {
    title: string,
    href: string,
    buttonText?: string,
} & BoxProps) {
    const FlairedHeading = styled(Heading)`
        position: relative;
        z-index: 5;
        &:before {
            display: block;
            content: '';
            width: 60px;
            height: 30px;
            position: absolute;
            top: 15px;
            left: -45px;
            z-index: -1;
            background-color: ${flairColor};
            opacity: 0.4;
        }
        &:after {
            display: block;
            content: '';
            width: 30px;
            height: 30px;
            position: absolute;
            top: -20px;
            left: 15px;
            z-index: -1;
            background-color: ${flairColor};
            opacity: 0.4;
        }
    `;

    return (
        <Section wrap vertical="lg" bg="secondaryBackground" {...props}>
            <Flex
                direction={{ _: 'column', md: 'row' }}
                justify={{ _: 'center', md: 'space-between' }}
                align="center"
            >
                <FlairedHeading
                    kind="h4"
                    mr={{ _: 0, md: 'spacing-lg' }}
                    mb={0}
                    maxWidth={{ _: 'auto', md: '500px' }}
                    textAlign={{ _: 'center', md: 'left' }}
                    flairColor={flairColor}
                >
                    {title}
                </FlairedHeading>

                <Box mt={{ _: 'spacing-lg', md: 0 }}>
                    <Button href={href}>{buttonText}</Button>
                </Box>
            </Flex>
        </Section>
    );
}
