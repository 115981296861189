import React from 'react';
import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import { AnimatedAppGraphic } from 'src/components/AnimatedAppGraphic';
import { AnimatedDualMedia } from 'src/components/AnimatedDualMedia';
import { Box } from 'src/components/Box';
import { CheckListItem } from 'src/components/CheckListItem';
import { Flex } from 'src/components/Flex';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { AppExperiencesPhones } from 'src/components/Methods/app/AppExperiencesPhones';
import { BoxGrid } from 'src/components/Methods/app/BoxGrid';
import { MethodsCTA } from 'src/components/Methods/MethodsCTA';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { ClientList } from 'src/components/OurClientsAlt/OurClientsAlt';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';
import { ThemeProvider } from 'src/components/ThemeProvider';

const subnav = [
    {
        id: 'features',
        label: 'Features',
    },
    {
        id: 'wordpress',
        label: 'Wordpress',
    },
    {
        id: 'metrics',
        label: 'Metrics',
    },
    {
        id: 'process',
        label: 'Process',
    },
];

export default function app({ data }) {
    const flairColor = '#F0932B';
    return (
        <Layout
            pageTitleFull="Make your own community app"
            siteDescription="Connect and engage with your audience via your own mobile app dedicated to your online community."
            contactForm
            methodSubNavIcon="/methods/app.svg"
            methodSubNavColor={flairColor}
            subnavTitle="App"
            subnav={subnav}
            wrapChildren
        >
            <MethodsLandingHero
                title="Make your own community app"
                subtitle="Create a social network designed for your community that lets members stay connected and engage with ease natively on their mobile device."
                mediaProps={{
                    width: '100%',
                }}
                customMedia={
                    <Flex bg="#FFF6E6" w="100%" justify="center">
                        <Box width={{ _: '100%', md: '100%', lg: '50%' }}>
                            <Box
                                width={{ _: '50%', md: '300px', lg: '100%' }}
                                mx="auto"
                            >
                                <AnimatedAppGraphic />
                            </Box>
                        </Box>
                    </Flex>
                }
            />

            <LandingFeatureGrid
                title="Be with your audience"
                items={[
                    {
                        heading: 'reach your audience where they are',
                        content:
                            'Put your community in the palm of people’s hands and reach them with native push notifications on iOS and Android.',
                    },
                    {
                        heading: 'get listed in app stores',
                        content:
                            'Gain exposure to a wider audience and a younger demographic.',
                    },
                    {
                        heading: 'expand your revenue opportunities',
                        content:
                            'An avenue for placing mobile ads, app sales, subscriptions, and in-app purchases.',
                    },
                    {
                        heading: 'utilize your software',
                        content:
                            'Pair the app with WordPress and forum software like XenForo.',
                    },
                    {
                        heading: 'your own dedicated  app',
                        content:
                            'Create the experience you want with your branding and stop competing for your audience’s attention in other social media networks with an app built just for your community.',
                    },
                    {
                        heading: 'modern and easy user experience',
                        content:
                            'Provide the simplicity of a native user experience while maintaining the power of a forum.',
                    },
                ]}
                mb="0"
            />

            <Section
                wrap
                vertical="lg"
                bg="secondaryBackground"
                id={subnav[0].id}
            >
                {/* This isn't really a media section, but the component allows for a custom content section, and helps us stick with a consistent layout and structure */}
                <MediaSection
                    wrap={false}
                    vertical="md"
                    title="Familiar experiences and interactions"
                    flipped
                    pt={[0, 0, 0]}
                    px={[0, 0, 0]}
                    customMedia={
                        <Flex>
                            <Paragraph color="bodyText" maxW={525}>
                                With a modern and easy user experience,
                                community members can quickly interact with each
                                other in a variety of ways.
                            </Paragraph>
                        </Flex>
                    }
                />
                <AppExperiencesPhones
                    theSlides={[
                        {
                            title: 'in-app purchases',
                            subtext:
                                'When prompted, users can pay directly within the app for subscriptions and donations.',
                            image: data.apppurchases.childImageSharp.fluid,
                        },
                        {
                            title: 'profile statuses',
                            subtext:
                                'Easily update your status, browse others, and reply and react to them.',
                            image: data.profilestatus.childImageSharp.fluid,
                        },
                        {
                            title: 'discussions',
                            subtext: 'Start, join, or read discussions.',
                            image: data.discussions.childImageSharp.fluid,
                        },
                        {
                            title: 'streaming',
                            subtext:
                                'Start streaming to your audience from the same environment in a couple clicks.',
                            image: data.streaming.childImageSharp.fluid,
                        },
                        {
                            title: 'push notifications',
                            subtext:
                                'Notify members of activity and messages on their iOS or Android device.',
                            image: data.notifications.childImageSharp.fluid,
                        },
                        {
                            title: 'native ads',
                            subtext:
                                'Enhance your revenue stream with app-friendly advertising.',
                            image: data.nativeads.childImageSharp.fluid,
                        },
                        {
                            title: 'private conversations',
                            subtext:
                                'Chat privately and get notified of messages.',
                            image:
                                data.privateconversations.childImageSharp.fluid,
                        },
                        {
                            title: 'reactions',
                            subtext: 'React to content easily.',
                            image: data.reactions.childImageSharp.fluid,
                        },
                        {
                            title: 'user preferences',
                            subtext:
                                'Save, follow, hide, watch content and more with a simple swipe.',
                            image: data.userpreferences.childImageSharp.fluid,
                        },
                    ]}
                />
            </Section>

            <ThemeProvider defaultMode="dark">
                <MediaSection
                    id={subnav[1].id}
                    title="WordPress integration so your articles have direct engagement"
                    flipped
                    bg="primaryBackground"
                    customMedia={
                        <AnimatedDualMedia
                            image={data.articlelisting.childImageSharp.fluid}
                            alt1="WordPress integration with community app that displays article listing."
                            image2={data.articleview.childImageSharp.fluid}
                            alt2="WordPress integration with community app that displays article view and article comments."
                        />
                    }
                >
                    <Box pt="spacing" as="ul">
                        {[
                            {
                                title:
                                    'Articles have a dedicated space within the app',
                                content:
                                    'Articles are shown on their own tab and users can set it as their preferred landing feed.',
                            },
                            {
                                title: 'No browser redirects',
                                content:
                                    'Articles are rendered quickly and seamlessly without navigating users away from the app.',
                            },
                            {
                                title: 'Article comment system',
                                content:
                                    'With additional configuration, the comment system on articles can be displayed in the app.',
                            },
                        ].map(highlight => (
                            <CheckListItem
                                key={highlight.title}
                                title={highlight.title}
                                as="li"
                            >
                                {highlight.content}
                            </CheckListItem>
                        ))}
                    </Box>
                </MediaSection>
            </ThemeProvider>

            <MediaSection
                title="metrics we've seen"
                id={subnav[2].id}
                flipped
                image={data.metrics.childImageSharp.fluid}
            >
                <Paragraph>
                    After following the progress of several clients and their
                    users, here are some avarages we saw
                </Paragraph>
                <Box pt="spacing" as="ul">
                    {[
                        {
                            title: '119% increase in registrations',
                        },
                        {
                            title: '91% increase in daily active users',
                        },
                        {
                            title: '49% increase in threads',
                        },
                        {
                            title: '47% increase in posts',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                            as="li"
                        />
                    ))}
                </Box>
            </MediaSection>

            <Section vertical="md" pt={[0, 0, 0]} wrap bg="secondaryBackground">
                <ClientList
                    title="Valued Partners"
                    clients={[
                        { name: 'IGN', image: data.ign },
                        { name: 'cPanel', image: data.cpanel },
                        { name: 'Yahoo!', image: data.yahoo },
                        { name: 'MacRumors', image: data.macrumors },
                        { name: "Tom's hardware", image: data.tomshardware },
                    ]}
                    borderTop="1px"
                    borderColor="border"
                    pt={['spacing-lg', '40px', 'spacing-xl']}
                />
            </Section>

            <Section
                id={subnav[2].id}
                vertical="md"
                // pb={[0, 0, 0]}
                wrap
                // bg="secondaryBackground"
            >
                {/* <ClientList
                    title="Valued Partners"
                    clients={[
                        { name: 'IGN', image: data.ign },
                        { name: 'cPanel', image: data.cpanel },
                        { name: 'Yahoo!', image: data.yahoo },
                        { name: 'MacRumors', image: data.macrumors },
                        { name: "Tom's hardware", image: data.tomshardware },
                    ]}
                    borderBottom="1px"
                    borderColor="border"
                    pb={['spacing-lg', '40px', 'spacing-xl']}
                /> */}

                <MethodsCTA
                    title="Partner with us to make your own community app"
                    flairColor={flairColor}
                    bg="primaryBackground"
                />

                <BoxGrid
                    steps={[
                        {
                            number: '01',
                            heading: 'Develop',
                            content:
                                'We will build and customize your app uniquely for your community.',
                        },
                        {
                            number: '02',
                            heading: 'Deploy',
                            content:
                                'Leading the deployment, we will submit your app to app stores and manage feedback for approval.',
                        },
                        {
                            number: '03',
                            heading: 'Updates',
                            content:
                                'Based on your needs, we will work together on planning and implementing updates.',
                        },
                    ]}
                />
            </Section>
        </Layout>
    );
}

export const query = graphql`
    query appQuery {
        vbhero: file(
            relativePath: { eq: "images/vbsoftware/talking-point.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        revenuestreams: file(
            relativePath: { eq: "images/xfsoftware/revenue-streams.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        streaminterface: file(
            relativePath: { eq: "images/xfsoftware/stream-interface.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        ign: file(relativePath: { eq: "images/clients/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        tomshardware: file(
            relativePath: { eq: "images/clients/toms-hardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        macrumors: file(relativePath: { eq: "images/clients/macrumors.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        yahoo: file(relativePath: { eq: "images/clients/yahoo-sports.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        apppurchases: file(
            relativePath: { eq: "images/app-method/slider/app-purchases.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        discussions: file(
            relativePath: { eq: "images/app-method/slider/discussions.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        metrics: file(relativePath: { eq: "images/app-method/metrics.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        mediaresources: file(
            relativePath: { eq: "images/app-method/slider/media-resources.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        nativeads: file(
            relativePath: { eq: "images/app-method/slider/native-ads.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        privateconversations: file(
            relativePath: {
                eq: "images/app-method/slider/private-conversations.png"
            }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        profilestatus: file(
            relativePath: { eq: "images/app-method/slider/profile-status.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        reactions: file(
            relativePath: { eq: "images/app-method/slider/reactions.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        streaming: file(
            relativePath: { eq: "images/app-method/slider/streaming.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        userpreferences: file(
            relativePath: {
                eq: "images/app-method/slider/user-preferences.png"
            }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        notifications: file(
            relativePath: { eq: "images/app-method/slider/notifications.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        articlelisting: file(
            relativePath: { eq: "images/app-method/article-listing.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        articleview: file(
            relativePath: { eq: "images/app-method/article-view.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
