import { Box } from 'components/Box';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import React from 'react';
import { Grid } from 'src/components/Grid';
import { ThemeProvider } from 'src/components/ThemeProvider';

export const BoxGrid = ({ steps = [], ...props }: any) => {
    return (
        <Grid minChildWidth={275} {...props}>
            {steps.map((step, index) => (
                <MethodItem
                    key={step.number}
                    heading={step.heading}
                    number={step.number}
                    content={step.content}
                />
            ))}
        </Grid>
    );
};

function MethodItem({ number, heading, content }) {
    return (
        <ThemeProvider defaultMode="dark">
            <Box p="spacing-xl" bg="primaryBackground">
                <Heading
                    kind="h1"
                    as="p"
                    color="faintText"
                    pb="spacing-lg"
                    mb={0}
                >
                    {number}
                </Heading>
                <Heading kind="h4">{heading}</Heading>

                <Paragraph color="bodyText">{content}</Paragraph>
            </Box>
        </ThemeProvider>
    );
}
