import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Box } from 'src/components/Box';
import 'swiper/swiper-bundle.css';
import { Video } from '../Video';

const HeroFlair = ({
    top,
    bottom,
    left,
    right,
    height,
    img,
    anim,
    animDuration,
    zIndex = 1,
}) => (
    <div
        css={css`
            position: absolute;
            height: ${height};
            top: ${top};
            bottom: ${bottom};
            left: ${left};
            right: ${right};
            animation: ${anim} ${animDuration} ease-in-out infinite;
            z-index: ${zIndex};
        `}
    >
        <img alt="" src={img} style={{ height: '100%' }} />
    </div>
);

export const AnimatedAppGraphic = () => {
    const data = useStaticQuery(appGraphicQuery);
    const floatAnim = keyframes`
        0% {
            transform: translatey(10px);
        }
        50% {
            transform: translatey(-20px);
        }
        100% {
            transform: translatey(10px);
        }
    `;

    const floatReverseAnim = keyframes`
        0% {
        transform: translatey(-20px);
        }
        50% {
            transform: translatey(0px);
        }
        100% {
            transform: translatey(-20px);
        }
    `;

    const floatSmallAnim = keyframes`
        0% {
            transform: translatey(-5px);
        }
        50% {
            transform: translatey(5px);
        }
        100% {
            transform: translatey(-5px);
        }
    `;

    const Phonebg = styled(Box)`
        position: relative;
        perspective: 800;
        -webkit-perspective: 800;
        :after {
            content: '';
            padding: 90%;
            border-radius: 50%;
            background-color: orange;
            opacity: 0.1;
            position: absolute;
            top: 0;
            left: -38%;
            z-index: 0;
        }
    `;

    const Appflair = styled(Box)`
        position: absolute;
        /* z-index: 1; */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    `;

    return (
        <Phonebg>
            <Box //Phone
                position="relative"
                height="100%"
                zIndex="2"
                transform="rotate(10deg)"
                transformStyle="preserve-3d"
            >
                <img
                    alt=""
                    src={'/notch.svg'}
                    style={{
                        position: 'absolute',
                        top: '6%',
                        left: '0',
                        right: '0',
                        margin: 'auto',
                        zIndex: '6',
                        width: '35%',
                    }}
                />
                <Img
                    alt=""
                    fluid={data.heroDevice.childImageSharp.fluid}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
                <Box
                    py="12%"
                    px="16%"
                    style={{
                        position: 'absolute',
                        top: '0%',
                        left: '0%',
                        width: 'auto',
                        zIndex: '5',
                        overflow: 'hidden',
                        backfaceVisibility: 'hidden',
                    }}
                >
                    <Video
                        autoPlay
                        video="/videos/methods/streaming/app-hero-new.mp4"
                        videoPoster="/videos/methods/streaming/phone-app-poster.jpg"
                        videoId="phone-app"
                        style={{
                            backfaceVisibility: 'hidden',
                            borderRadius: '30px',
                            backgroundColor: '#fff',
                        }}
                    />
                </Box>
            </Box>
            <Appflair>
                <HeroFlair
                    height="15%"
                    top="5%"
                    bottom="auto"
                    left="-15%"
                    right="auto"
                    img="/commapp/emoji.png"
                    anim={floatReverseAnim}
                    animDuration="16s"
                />
                <HeroFlair
                    height="15%"
                    top="-5%"
                    bottom="auto"
                    left="auto"
                    right="-15%"
                    img="/commapp/like1.png"
                    anim={floatAnim}
                    animDuration="13s"
                />
                <HeroFlair
                    height="15%"
                    top="40%"
                    bottom="auto"
                    left="auto"
                    right="-40%"
                    img="/commapp/chat1.png"
                    anim={floatReverseAnim}
                    animDuration="10s"
                />
                <HeroFlair
                    height="15%"
                    top="auto"
                    bottom="10%"
                    left="auto"
                    right="-30%"
                    img="/commapp/like2.png"
                    anim={floatSmallAnim}
                    animDuration="4s"
                />
                <HeroFlair
                    height="25%"
                    top="auto"
                    bottom="-15%"
                    left="auto"
                    right="0%"
                    img="/commapp/chat2.png"
                    anim={floatReverseAnim}
                    animDuration="10s"
                />
                <HeroFlair
                    height="25%"
                    top="auto"
                    bottom="-10%"
                    left="-40%"
                    right="auto"
                    img="/commapp/bell.png"
                    anim={floatAnim}
                    animDuration="6s"
                />
                <HeroFlair
                    height="28%"
                    top="auto"
                    bottom="30%"
                    left="-45%"
                    right="auto"
                    img="/commapp/like3.png"
                    anim={floatAnim}
                    animDuration="6s"
                    zIndex={3}
                />
            </Appflair>
        </Phonebg>
    );
};

export const appGraphicQuery = graphql`
    query AppGraphicQuery {
        heroDevice: file(relativePath: { eq: "images/commapp/device.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
