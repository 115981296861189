import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { Box } from 'src/components/Box';
import { Flex } from 'src/components/Flex';
import { IO } from 'src/components/IO';
import { useTheme } from 'src/components/ThemeProvider';
import { useWindowResize } from 'src/helpers/useWindowResize';
import { AnimatedContainer } from '../AnimatedContainer';
import { Video } from '../Video';

export const CircleFlair = () => {
    return (
        <Box
            borderRadius="100%"
            width="7vw"
            height="7vw"
            border="2px"
            borderColor="boldBorder"
            position="absolute"
            top="-1vw"
            left="-1.5vw"
            zIndex="0"
            display={{ _: 'none', md: 'block' }}
        />
    );
};

interface AnimatedDualMediaProps {
    image?: string;
    video?: string;
    videoId?: string;
    videoPoster?: string;
    image2?: string;
    video2?: string;
    video2Id?: string;
    video2Poster?: string;
    alt1: string;
    alt2: string;
}

export const AnimatedDualMedia = ({
    image,
    video,
    videoId,
    videoPoster,
    image2,
    video2,
    video2Id,
    video2Poster,
    alt1,
    alt2,
}: AnimatedDualMediaProps) => {
    const { breakpoint } = useTheme();
    const { windowWidth } = useWindowResize();

    // TODO: look into issue with windowWidth not recalculating upon initial mounting
    // renders with windowWidth = 0 due to !__BROWSER__, but doesn't update afterwards
    // manually forcing a change upon mount with the below
    const [withAnimation, setWithAnimation] = useState(false);
    const animated = windowWidth > breakpoint.stripped.lg;
    useEffect(() => {
        if (animated) {
            setWithAnimation(true);
        } else {
            setWithAnimation(false);
        }
    }, [animated]);

    const hasFirstMedia = image || video;
    const hasSecondMedia = image2 || video2;

    if (!withAnimation) {
        return (
            <Flex key="dual_mobile" pos="relative" w="100%">
                {hasFirstMedia && (
                    <MediaItem
                        image={image}
                        video={video}
                        videoId={videoId}
                        videoPoster={videoPoster}
                        flex={1}
                        alt={alt1}
                    />
                )}
                {hasSecondMedia && (
                    <MediaItem
                        image={image2}
                        video={video2}
                        videoId={video2Id}
                        videoPoster={video2Poster}
                        flex={1}
                        alt={alt2}
                    />
                )}
            </Flex>
        );
    }

    return (
        <Flex pos="relative" w="100%">
            <CircleFlair />

            <IO rootMargin="-50%" w="100%">
                {({ isVisible }) => {
                    return (
                        <AnimatedContainer
                            key="animated_container"
                            isAnimated={isVisible}
                            offset={{
                                initial: {
                                    scale: 0.85,
                                },
                                final: {
                                    scale: 0.95,
                                },
                            }}
                            style={{
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            {hasFirstMedia && (
                                <AnimatedContainer
                                    isAnimated={isVisible}
                                    offset={{
                                        initial: {
                                            y: 0,
                                        },
                                        final: {
                                            y: 40,
                                        },
                                    }}
                                    style={{
                                        width: '50%',
                                    }}
                                >
                                    <MediaItem
                                        image={image}
                                        video={video}
                                        videoId={videoId}
                                        videoPoster={videoPoster}
                                        alt={alt1}
                                        w="100%"
                                    ></MediaItem>
                                </AnimatedContainer>
                            )}

                            {hasSecondMedia && (
                                <AnimatedContainer
                                    isAnimated={isVisible}
                                    offset={{
                                        initial: {
                                            y: 0,
                                        },
                                        final: {
                                            y: -40,
                                        },
                                    }}
                                    style={{
                                        width: '50%',
                                    }}
                                >
                                    <MediaItem
                                        image={image2}
                                        video={video2}
                                        videoId={video2Id}
                                        videoPoster={video2Poster}
                                        w="100%"
                                        alt={alt2}
                                    />
                                </AnimatedContainer>
                            )}
                        </AnimatedContainer>
                    );
                }}
            </IO>
        </Flex>
    );
};

const MediaItem = ({ image, video, videoId, videoPoster, alt, ...rest }) => {
    return (
        <Box
            px={['spacing', null, 'spacing-lg']}
            boxSizing="border-box"
            pos="relative"
            {...rest}
        >
            {image && (
                <Img
                    style={{
                        // custom radius for phone images
                        borderRadius: '15px',
                    }}
                    fluid={image}
                    alt={alt}
                />
            )}
            {video && (
                <Video
                    video={video}
                    videoId={videoId}
                    videoPoster={videoPoster}
                    rounded="15px"
                />
            )}
        </Box>
    );
};
